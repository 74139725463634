<template>
  <div>
    <pm-Card>
      <template v-slot:title> dados pessoais </template>
      <template v-slot:subtitle> Insira os dados abaixo </template>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <h5>Conectividade:</h5>
          </div>
          <div class="row field col-12 md:col-3">
            <h6>Possui dispositivo com acesso à internet?</h6>
            <div class="field-radiobutton">
              <pm-RadioButton
                name="sim"
                value="1"
                v-model="info.conectividade"
              />
              <label for="solteiro">Sim</label>
              <pm-RadioButton
                name="nao"
                value="0"
                v-model="info.conectividade"
                style="margin-left: 7px"
              />
              <label for="casado">Não</label>
            </div>
          </div>
          <div class="field col-12 md:col-3" v-if="info.conectividade == '1'">
            <label for="sangue">Tipo de dispositivo:</label>
            <pm-MultiSelect
              v-model="info.tipos_dispotivos"
              :options="dispositivoALL"
              placeholder="Selecione um ou mais"
              :class="{
                'p-invalid': validationErrors.tipos_dispotivos && submitted,
              }"
            />
            <small
              v-show="validationErrors.tipos_dispotivos && submitted"
              class="p-error"
            >Selecione pelo menos um dispositivo!</small
            >
          </div>
          <!--<div class="field col-12 md:col-12">
                       <br><h5>Acesso ao sistema:</h5>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="firstname">Email:</label>
                        <pm-InputText id="email" :style="greenColor" v-model="info.email" v-on:blur="verificarEmail" />
                        <small v-show="emailInvalidoJaExiste" class="p-error">O Email digitado já está em uso!</small>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="senha">Senha:</label>
                        <pm-Password v-model="info.senha"  weakLabel='Fraca' mediumLabel='Média' strongLabel='Forte' toggleMask :class="{'p-invalid': validationErrors.senha && submitted}"></pm-Password>
                        <small v-show="validationErrors.senha && submitted" class="p-error">As Senhas não coincidem!</small>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="senha">Confirmar senha:</label>
                        <pm-Password v-model="info.confirmarSenha"  weakLabel='Fraca' mediumLabel='Média' strongLabel='Forte' toggleMask :class="{'p-invalid': validationErrors.confirmarSenha && submitted}"></pm-Password>
                        <small v-show="validationErrors.confirmarSenha && submitted" class="p-error">Senhas não coincidem!</small>
                    </div>-->

          <div class="field col-12 md:col-2">
            <label for="senha">Adicionar celular:</label>
            <!--<pm-InputText id="celular" v-model="celular"  @keyup.enter="adicionarTelefone(1)"  />-->
            <pm-InputText
              id="celular"
              v-model="celular1"
              placeholder="Digite o número"
              @keyup.enter="adicionarTelefone(1)"
              v-mask="{ mask: '(NN) NNNNN-NNNN' }"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label for="senha">À quem pertence o celular ? </label>
            <pm-Dropdown
              :style="errorColor"
              v-model="selected"
              :options="selecIdentificador"
            />
            <small v-if="isNull" v-show="isNull" class="p-error"
            >Selecione um campo.
            </small>
          </div>

          <div class="field col-12 md:col-2">
            <label>&ensp;</label>

            <pm-Button
              type="button"
              @click="adicionarTelefone(1)"
              icon="pi pi-plus"
              label="Adicionar Número"
              title="adicionar"
              class="p-button-sm btn-color"
            ></pm-Button>
          </div>
        </div>

        <pm-DataTable
          :value="info.telefone"
          v-if="info.telefone.length"
          dataKey="nome"
          :rowHover="true"
          filterDisplay="menu"
          v-model:filters="filters"
          :globalFilterFields="['nome']"
          responsiveLayout="scroll"
        >
          <template #header> Você pode editar ou deletar um número </template>
          <pm-Column
            field="nome"
            sortable
            header="Celular"
            style="font-size: 14px; vertical-align: middle !important"
          >
          </pm-Column>
          <pm-Column
            field="identificador"
            sortable
            header="Possuinte"
            style="font-size: 14px; vertical-align: middle !important"
          >
          </pm-Column>
          <pm-Column
            field="nome"
            headerStyle="width: 4rem; text-align: center"
            bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important"
          >
            <template #body="{ data }">
              <pm-Button
                type="button"
                @click="oTelefoneSeraCadastradoOuEditado(2, data.nome)"
                icon="pi pi-pencil"
                label="Editar"
                title="Editar"
                class="p-button-sm btn-color"
              ></pm-Button>
              <pm-Button
                title="Apagar"
                type="button"
                @click="deletarCelular(data)"
                label="Apagar"
                icon="pi pi-times-circle"
                class="p-button-danger p-button-sm btn-color"
                style="margin-left: 5px"
              ></pm-Button>
            </template>
          </pm-Column>
        </pm-DataTable>
      </template>

      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button
            label="Anterior"
            @click="prevPage()"
            icon="pi pi-angle-left"
          />
          <pm-Button
            label="Próximo"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </pm-Card>

    <pm-Dialog v-model:visible="display2">
      <template #header>
        <h3>Editar número de telefone</h3>
      </template>

      <div class="row">
        <div class="field col-12 md:col-6">
          <label for="numero">Número</label><br />
          <pm-InputText
            id="celular"
            v-model="celular3"
            @keyup.enter="adicionarTelefone(2)"
            v-mask="{ mask: '(NN) NNNNN-NNNN', model: 'celular3' }"
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="identificador">Possuinte</label><br />
          <pm-Dropdown v-model="selected2" :options="selecIdentificador" />
        </div>
      </div>

      <template #footer>
        <pm-Button
          v-if="cadastrarOuEditarCelular == 2"
          label="Editar"
          icon="pi pi-check"
          autofocus
          @click="adicionarTelefone(2)"
        />
      </template>
    </pm-Dialog>
  </div>
</template>

<script>
import { Verificar } from "@/class/verificar.js";
import { Telefone } from "@/class/telefone.js";
import { Aluno } from "@/class/alunos.js";

export default {
  props: {
    dadosPessoa: { type: Array, default: () => [] },
    pg: {},
  },
  data() {
    return {
      dispositivoALL: ["CELULAR", "COMPUTADOR", "TABLET", "OUTROS"],
      display2: false,
      emailInvalidoJaExiste: "",
      greenColor: "",
      errorColor: "",
      isNull: "",
      info: {
        nome: "",
        nomeMae: "",
        nomePai: "",
        possuiDeficiencia: false,
        deficiencia: "",
        nee: false,
        observacao: "",
        email: "",
        senha: "",
        estadocivil: "Solteiro",
        certidaoCasamento: "",
        certidaoNascimento: "",
        rg: "",
        dataEmissao: "",
        cpf: "",
        dataNascimento: "",
        sexo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone: [],
        confirmarSenha: "",
        conectividade: "0",
        escola_id:0,
      },
      faltaDispositivo: false,
      celular1: "",
      celular2: "",
      celular3: "",
      cadastrarOuEditarCelular: -1,
      indexNumeroEditado: -1,
      submitted: false,
      validationErrors: {},
      selecIdentificador: ["Aluno", "Responsável"],
      selected: "",
      selected2: "",
    };
  },
  methods: {
    async verificarEmail() {
      if (this.info.email == "" || this.info.email == null) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
        return false;
      }

      const data = await Verificar.verificarJaExisteEmail(this.info.email);
      try {
        if (this.info.id == undefined) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else if (
          this.info.id != undefined &&
          this.info.email !== this.info.emailDele
        ) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else {
          this.emailInvalidoJaExiste = false;
          this.greenColor = "";
        }
      } catch (e) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
      }
    },
    validateForm() {
      const ok = this.info.senha === this.info.confirmarSenha;

      if (!ok) {this.validationErrors["senha"] = true;}
      else {delete this.validationErrors["senha"];}

      if (!ok) {this.validationErrors["confirmarSenha"] = true;}
      else {delete this.validationErrors["confirmarSenha"];}

      if (
        this.info.conectividade == "1" &&
        (this.info.tipos_dispotivos == "" ||
          this.info.tipos_dispotivos == undefined)
      )
        {this.validationErrors["tipos_dispotivos"] = true;}
      else {delete this.validationErrors["tipos_dispotivos"];}
      return !Object.keys(this.validationErrors).length;
    },
    nextPage() {
      this.submitted = true;
      if (this.validateForm() && this.emailInvalidoJaExiste != true)
        {this.$emit("nextPage", { info: this.info });}
    },
    prevPage() {
      this.submitted = true;
      if (this.validateForm() && this.emailInvalidoJaExiste != true)
        {this.$emit("prevPage", { info: this.info });}
    },
    isNumber(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0123456789]+$/.test(char)) {return true;}
      // Match with regex
      else {e.preventDefault();} // If not match, don't add to input text
    },
    oTelefoneSeraCadastradoOuEditado(acao, celular) {
      if (acao == 2) {
        for (let i = 0; i < this.info.telefone.length; i++) {
          if (celular === this.info.telefone[i].nome) {
            this.indexNumeroEditado = i;
            this.celular2 = this.info.telefone[i];
            this.celular3 = this.info.telefone[i].nome;
            this.selected2 = this.info.telefone[i].identificador;
            break;
          }
        }
      } else {
        this.celular1 = "";
      }
      this.cadastrarOuEditarCelular = acao;
      this.display2 = true;
    },
    async adicionarTelefone(acao) {
      let telVerificar = "";

      if (acao == 1) {
        telVerificar = this.celular1;
        this.selected;
      } else {
        telVerificar = this.celular3;
        this.selected2;
      }

      telVerificar = telVerificar.replace(/[^0-9]/g, "");
      let telefone_form;

      for (let index = 0; index < telVerificar.length; index++) {
        if (index > 0) {
          if (index < 11) {
            telefone_form = `${telefone_form}${telVerificar[index]}`;
          }
        } else {
          telefone_form = `${telVerificar[index]}`;
        }
      }
      telVerificar = telefone_form;

      if (telVerificar.length < 9) {
        return false;
      }

      for (let i = 0; i < this.info.telefone.length; i++) {
        if (telVerificar === this.info.telefone[i].nome) {
          return false;
        }
      }

      //verificar se o número ja existe no banco
      // let jaExisteTelefone = await Telefone.obtemTelefone(telVerificar);
      // if (jaExisteTelefone.data.telefone != undefined) {
      //   alert("O número digitado já existe na base de dados!");
      //   return false;
      // } 
      //Se tiver cadastrando
      if (this.info.id == undefined) {
        if (acao == 1) {
          //verificação para obrigar que o usuário escolha um identificador caso o exista um número no input
          if (this.telVerificar != "" && this.selected == "") {
            this.isNull = true;
            this.errorColor = "border-color: #f44336;";
            return false;
          } else {
            this.isNull = false;
            this.errorColor = "";
            this.info.telefone.push({
              nome: telVerificar,
              identificador: this.selected,
            });
            this.celular1 = "";
            this.selected = "";
            return true;
          }
        } else {
          this.info.telefone[this.indexNumeroEditado].nome = telVerificar;
          this.celular2 = "";
        }
      } else {
        if (acao == 1) {
          if (this.telVerificar != "" && this.selected == "") {
            this.isNull = true;
            this.errorColor = "border-color: #f44336;";
            return false;
          } else {
            this.isNull = false;
            this.errorColor = "";
            const data = await Telefone.addTelefone(
              0,
              this.info.id,
              telVerificar,
              this.selected
            );
            this.celular1 = "";
            this.selected = "";
            this.atualizarTelefones();
          }
        } else {
          const novo = {
            id: this.celular2.id,
            telefone: telVerificar,
            identificador: this.selected2,
          };
          const data = await Telefone.editarTelefone(novo);
          this.celular2 = "";
          this.selected2 = "";
          this.atualizarTelefones();
        }
      }
      this.display2 = false;
    },
    async atualizarTelefones() {
      const data = await Aluno.obtemUm(this.info.id);
      const result = data.data;
      this.info.telefone = [];
      for (const el of result[0].telefone) {
        this.info.telefone.push({
          id: el.id,
          nome: el.telefone,
          identificador: el.identificador,
        });
      }
    },
    async deletarCelular(celular) {
      //Se for cadastrar
      if (this.info.id == undefined) {
        if (
          window.confirm("Você realmente deseja apagar o número selecionado?")
        ) {
          for (let i = 0; i < this.info.telefone.length; i++) {
            if (celular.nome === this.info.telefone[i].nome) {
              this.info.telefone.splice(i, 1);
              break;
            }
          }
        }
      } //Se for editar
      else {
        if (
          window.confirm("Você realmente deseja apagar o número selecionado?")
        ) {
          const data = await Telefone.removerTelefone(celular.id);
          this.atualizarTelefones();
        }
      }
    },
  },
  beforeMount() {
    console.log(this.dadosPessoa);
    this.info.nome =
      this.dadosPessoa.nome != undefined
        ? this.dadosPessoa.nome
        : this.info.nome;
    this.info.sobrenome =
      this.dadosPessoa.sobrenome != undefined
        ? this.dadosPessoa.sobrenome
        : this.info.sobrenome;
    this.info.nomeMae =
      this.dadosPessoa.nomeMae != undefined
        ? this.dadosPessoa.nomeMae
        : this.info.nomeMae;
    this.info.nomePai = this.dadosPessoa.nomePai;
    this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
    this.info.deficiencia = this.dadosPessoa.deficiencia;
    this.info.observacao = this.dadosPessoa.observacao;
    this.info.nee = this.dadosPessoa.nee;
    this.info.mediacao_escolar = this.dadosPessoa.mediacao_escolar;
    this.info.email = this.dadosPessoa.email;
    this.info.emailPadrao = this.dadosPessoa.emailPadrao;
    this.info.emailEdicao = this.info.email;
    this.info.senha = this.dadosPessoa.senha;
    this.info.confirmarSenha = this.dadosPessoa.confirmarSenha;
    this.info.estadocivil =
      this.dadosPessoa.estadocivil != undefined
        ? this.dadosPessoa.estadocivil
        : this.info.estadocivil;
    this.info.certidaoNascimento = this.dadosPessoa.certidaoNascimento;
    this.info.certidaoCasamento = this.dadosPessoa.certidaoCasamento;
    this.info.rg = this.dadosPessoa.rg;
    this.info.rgDataEmissao =
      this.dadosPessoa.rgDataEmissao != undefined
        ? this.dadosPessoa.rgDataEmissao
        : this.info.rgDataEmissao;
    this.info.cpf = this.dadosPessoa.cpf;
    this.info.cpfDele = this.dadosPessoa.cpfDele;
    this.info.dataNascimento =
      this.dadosPessoa.dataNascimento != undefined
        ? this.dadosPessoa.dataNascimento
        : this.info.dataNascimento;
    this.info.cep =
      this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep;
    this.info.estado = this.dadosPessoa.estado;
    this.info.cidade = this.dadosPessoa.cidade;
    this.info.bairro = this.dadosPessoa.bairro;
    this.info.logradouro = this.dadosPessoa.logradouro;
    this.info.numero = this.dadosPessoa.numero;
    this.info.complemento = this.dadosPessoa.complemento;
    this.info.telefone =
      this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone;
    this.info.sexo =
      this.dadosPessoa.sexo != undefined
        ? this.dadosPessoa.sexo
        : this.info.sexo;
    this.info.foto = this.dadosPessoa.foto;
    this.info.id =
      this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id;
    this.info.login_id =
      this.dadosPessoa.login_id != undefined
        ? this.dadosPessoa.login_id
        : this.info.login_id;
    this.dadosPessoa.jaBuscou = true;
    this.info.matricula = this.dadosPessoa.matricula;
    this.info.alergico =
      this.dadosPessoa.alergico != undefined
        ? this.dadosPessoa.alergico
        : this.info.alergico;
    this.info.tipo_alergia =
      this.dadosPessoa.tipo_alergia != undefined
        ? this.dadosPessoa.tipo_alergia
        : this.info.tipo_alergia;
    this.info.nacionalidade =
      this.dadosPessoa.nacionalidade != undefined
        ? this.dadosPessoa.nacionalidade
        : this.info.nacionalidade;
    this.info.naturalidade =
      this.dadosPessoa.naturalidade != undefined
        ? this.dadosPessoa.naturalidade
        : this.info.naturalidade;
    this.info.cor =
      this.dadosPessoa.cor != undefined ? this.dadosPessoa.cor : this.info.cor;
    this.info.religiao =
      this.dadosPessoa.religiao != undefined
        ? this.dadosPessoa.religiao
        : this.info.religiao;
    this.info.aulas_religiao_frequentara =
      this.dadosPessoa.aulas_religiao_frequentara != undefined
        ? this.dadosPessoa.aulas_religiao_frequentara
        : "0";
    this.info.usoDaImage =
      this.dadosPessoa.usoDaImage != undefined
        ? this.dadosPessoa.usoDaImage
        : "0";
    this.info.responsavel =
      this.dadosPessoa.responsavel != undefined
        ? this.dadosPessoa.responsavel
        : "";
    this.info.orgao_emissor =
      this.dadosPessoa.orgao_emissor != undefined
        ? this.dadosPessoa.orgao_emissor
        : "";
    this.info.uf_rg =
      this.dadosPessoa.uf_rg != undefined ? this.dadosPessoa.uf_rg : "";
    this.info.livro_rg =
      this.dadosPessoa.livro_rg != undefined ? this.dadosPessoa.livro_rg : "";
    this.info.termo_rg =
      this.dadosPessoa.termo_rg != undefined ? this.dadosPessoa.termo_rg : "";

    this.info.nis =
      this.dadosPessoa.nis != undefined ? this.dadosPessoa.nis : "";
    this.info.sus =
      this.dadosPessoa.sus != undefined ? this.dadosPessoa.sus : "";

    this.info.folha_rg =
      this.dadosPessoa.folha_rg != undefined ? this.dadosPessoa.folha_rg : "";
    this.info.conectividade =
      this.dadosPessoa.conectividade != undefined
        ? this.dadosPessoa.conectividade
        : "0";

    this.info.tipos_dispotivos =
      this.dadosPessoa.tipos_dispotivos != undefined
        ? this.dadosPessoa.tipos_dispotivos
        : [];
    this.info.tipo_sanguineo =
      this.dadosPessoa.tipo_sanguineo != undefined
        ? this.dadosPessoa.tipo_sanguineo
        : "";
    this.info.utiliza_transporte =
      this.dadosPessoa.utiliza_transporte != undefined
        ? this.dadosPessoa.utiliza_transporte
        : "0";
    this.info.qual_transporte =
      this.dadosPessoa.qual_transporte != undefined
        ? this.dadosPessoa.qual_transporte
        : "";
    this.info.estadocivil =
      this.dadosPessoa.estadocivil != undefined
        ? this.dadosPessoa.estadocivil
        : "Solteiro";
    this.info.emailDele = this.dadosPessoa.emailDele;

    this.info.conectividade = "0";
    this.info.escola_id = this.dadosPessoa.escola_id;
  },
};
</script>
